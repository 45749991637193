html,
body,
#root {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #282c34;
  color: white;
}

body {
  font-family: "IBM Plex Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: white;
}
